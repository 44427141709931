<template>
  <img
    class="align-middle"
    :src="logo"
    :width="size"
  />
</template>

<script>

export default {
  name: 'Logo',
  props: {
    type: {
      type: String,
      default: 'square',
    },
    size: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    logo() {
      // eslint-disable-next-line import/no-dynamic-require
      return require(`../../assets/logos/cometum/${this.type}.png`);
    },
  },
};
</script>
